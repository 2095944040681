/* @import url(//use.fontawesome.com/releases/v5.8.2/css/all.css); */

@font-face {
  font-family: "SharpSansNo1-Black";
  src: url("./fonts/SharpSansNo1/SharpSansNo1-Black.woff") format("woff"),
    url("./fonts/SharpSansNo1/SharpSansNo1-Black.woff2") format("woff2"),
    url("./fonts/SharpSansNo1/SharpSansNo1-Black.otf") format("opentype"),
    url("./fonts/SharpSansNo1/SharpSansNo1-Black.ttf") format("truetype"),
    url("./fonts/SharpSansNo1/SharpSansNo1-Black.svg#SharpSansNo1-Black") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SharpSansNo1";
  src: url("./fonts/SharpSansNo1/SharpSansNo1-Book.woff") format("woff"),
    url("./fonts/SharpSansNo1/SharpSansNo1-Book.woff2") format("woff2"),
    url("./fonts/SharpSansNo1/SharpSansNo1-Book.otf") format("opentype"),
    url("./fonts/SharpSansNo1/SharpSansNo1-Book.ttf") format("truetype"),
    url("./fonts/SharpSansNo1/SharpSansNo1-Book.svg#SharpSansNo1-Book") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SharpSansNo1-Light";
  src: url("./fonts/SharpSansNo1/SharpSansNo1-Light.woff") format("woff"),
    url("./fonts/SharpSansNo1/SharpSansNo1-Light.woff2") format("woff2"),
    url("./fonts/SharpSansNo1/SharpSansNo1-Light.otf") format("opentype"),
    url("./fonts/SharpSansNo1/SharpSansNo1-Light.ttf") format("truetype"),
    url("./fonts/SharpSansNo1/SharpSansNo1-Light.svg#SharpSansNo1-Light") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SharpSansNo-Medium";
  src: url("./fonts/SharpSansNo1/SharpSansNo1-Medium.woff") format("woff"),
    url("./fonts/SharpSansNo1/SharpSansNo1-Medium.woff2") format("woff2"),
    url("./fonts/SharpSansNo1/SharpSansNo1-Medium.otf") format("opentype"),
    url("./fonts/SharpSansNo1/SharpSansNo1-Medium.ttf") format("truetype"),
    url("./fonts/SharpSansNo1/SharpSansNo1-Medium.svg#SharpSansNo1-Medium") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SharpSansNo1-Bold";
  src: url("./fonts/SharpSansNo1/SharpSansNo1-Bold.woff") format("woff"),
    url("./fonts/SharpSansNo1/SharpSansNo1-Bold.woff2") format("woff2"),
    url("./fonts/SharpSansNo1/SharpSansNo1-Bold.otf") format("opentype"),
    url("./fonts/SharpSansNo1/SharpSansNo1-Bold.ttf") format("truetype"),
    url("./fonts/SharpSansNo1/SharpSansNo1-Bold.svg#SharpSansNo1-Bold") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SharpSans-SemiBold";
  src: url("./fonts/SharpSansNo1/SharpSans-Semibold.woff") format("woff"),
    url("./fonts/SharpSansNo1/SharpSans-Semibold.woff2") format("woff2"),
    url("./fonts/SharpSansNo1/SharpSansNo1-Semibold.otf") format("opentype"),
    url("./fonts/SharpSansNo1/SharpSans-Semibold.ttf") format("truetype"),
    url("./fonts/SharpSansNo1/SharpSans-Semibold.svg#SharpSans-Semibold") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SharpSans-ExtraBold";
  src: url("./fonts/SharpSansNo1/SharpSansNo1-Extrabold.woff") format("woff"),
    url("./fonts/SharpSansNo1/SharpSansNo1-Extrabold.woff2") format("woff2"),
    url("./fonts/SharpSansNo1/SharpSansNo1-Extrabold.otf") format("opentype"),
    url("./fonts/SharpSansNo1/SharpSansNo1-Extrabold.ttf") format("truetype"),
    url("./fonts/SharpSansNo1/SharpSansNo1-Extrabold.svg#SharpSansNo1-Extrabold") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SourceSansPro";
  src: url("./fonts/SourceSansPro/SourceSansPro-Regular.woff") format("woff"),
    url("./fonts/SourceSansPro/SourceSansPro-Regular.woff2") format("woff2"),
    url("./fonts/SourceSansPro/SourceSansPro-Regular.ttf") format("truetype"),
    url("./fonts/SourceSansPro/SourceSansPro-Regular.otf") format("opentype"),
    url("./fonts/SourceSansPro/SourceSansPro-Regular.svg#SourceSansPro-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SourceSansPro-Bold";
  src: url("./fonts/SourceSansPro/SourceSansPro-Bold.woff") format("woff"),
    url("./fonts/SourceSansPro/SourceSansPro-Bold.woff2") format("woff2"),
    url("./fonts/SourceSansPro/SourceSansPro-Bold.ttf") format("truetype"),
    url("./fonts/SourceSansPro/SourceSansPro-Bold.svg#SourceSansPro-Bold") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SourceSansPro-Light";
  src: url("./fonts/SourceSansPro/SourceSansPro-Light.woff") format("woff"),
    url("./fonts/SourceSansPro/SourceSansPro-Light.woff2") format("woff2"),
    url("./fonts/SourceSansPro/SourceSansPro-Light.ttf") format("truetype"),
    url("./fonts/SourceSansPro/SourceSansPro-Light.svg#SourceSansPro-Light") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SourceSansPro-Italic";
  src: url("./fonts/SourceSansPro/SourceSansPro-It.woff") format("woff"),
    url("./fonts/SourceSansPro/SourceSansPro-Italic.woff2") format("woff2"),
    url("./fonts/SourceSansPro/SourceSansPro-Italic.ttf") format("truetype"),
    url("./fonts/SourceSansPro/SourceSansPro-It.svg#SourceSansPro-It") format("svg");
  font-weight: normal;
  font-style: normal;
}

/* common css start */

html {
  font-size: 16px;
}

body {
  background: #000;
  padding-top: 0px !important;
  font-family: "SharpSansNo1";
  color: #2a2a2a;
}

.row {
  margin: 0 auto;
  max-width: 1440px;
  width: 100%;
  display: block;
}

*,
::after,
::before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

*:focus {
  outline: none;
}

a {
  color: #fff;
  text-decoration: none;
  -webkit-transition: color 0.1s linear;
  -o-transition: color 0.1s linear;
  transition: color 0.1s linear;
}

a:hover {
  color: #fff;
}

strong {
  font-weight: bold;
}

pre {
  font-family: monospace;
  color: #323232;
  background: #f0f0f0;
  padding: 20px;
  margin-bottom: 30px;
  overflow-x: scroll;
}

img {
  height: auto;
  max-width: 100%;
}

form {
  overflow: hidden;
}

embed,
iframe,
object,
video {
  max-width: 100%;
}

:focus {
  outline-width: 2px;
  outline-color: #78aeda;
  outline-style: solid;
}

.no-padding {
  padding: 0px !important;
}

.full-width {
  width: 100% !important;
}

p {
  margin-bottom: 20px;
}

button,
select {
  text-transform: none;
  background: transparent;
  padding: 0;
  margin: 0;
  border: none;
}

.color-red {
  color: #ff0000;
}

.color-black {
  color: #000;
}

.color-white {
  color: #fff;
}

.bg-primrary {
  background: #009dd6;
}

.text-decoration {
  text-decoration: underline;
}

.content-container {
  clear: both;
}

.border-none {
  border: none !important;
}

h1,
.h1 {
  font-size: 90px;
  line-height: 90px;
  font-weight: 790;
}

h2,
.h2 {
  font-size: 48px;
  line-height: 65px;
  font-weight: 780;
}

h3,
.h3 {
  font-size: 32px;
  line-height: 40px;
  font-weight: 760;
}

h4,
.h4 {
  font-size: 22px;
  line-height: 28px;
  font-weight: 760;
}

h5,
.h5 {
  font-size: 18px;
  line-height: 22px;
  font-weight: 770;
}

h6,
.h6 {
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
}

.container-fluid {
  max-width: 1240px;
  margin: 0 auto;
  padding: 0;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.bg-dark {
  background: #fff;
  height: 100vh;
}

video {
  width: 100%;
}

.cust-btn {
  font-family: "SharpSans-SemiBold";
}

/* common css end */

/* animation css */

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* header css start */
header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
}

.header-navbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-animation: fadeIn 4s ease-in-out;
  animation: fadeIn 4s ease-in-out;
  background: rgba(0, 0, 0, 0.5);
}

.navbar-logo {
  margin-left: 40px;
}

.navbar-logo img {
  width: 256px;
}

.nav-find-job {
  cursor: pointer;
  background-color: #009dd6;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.find-job-link {
  font-size: 18px;
  color: #fff;
  line-height: 22px;
  display: block;
  padding: 23px 28px;
  min-width: 184px;
  text-align: center;
}

.right-arrow {
  padding-left: 13px;
}


/* header menu links */
.menu-link-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.menu-link-list li a {
  font-size: 16px;
  font-family: "SharpSans-SemiBold";
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #ffffff !important;
  margin: 0 22px;
  cursor: pointer;
}

.mob-hamburger-menu,
.mob-find-job-link,
.mob-menu-close {
  display: none;
}


/* Hero slider component css start */
.slider-container {
  position: relative;

}

.hero-slider-main-wrapper {
  width: 100%;
  height: 100vh;
  position: relative;
  -webkit-animation: fadeIn 10s ease;
  animation: fadeIn 10s ease;
  overflow: hidden;
  padding-bottom: 200vh;
}

.desktop-hero-slider {
  display: block;
  height: 100%;
  top: 0;
  left: 0;
  width: 100%;
  translate: none;
  rotate: none;
  scale: none;

}

.mobile-hero-slider {
  display: none;
}

.bg-dotted,
.bg-dotted-slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  opacity: 0.6;
  background-image: url("../../public/assets/images/dots-upper.svg"),
    url("../../public/assets/images/dots-lower.svg");
  background-position: right 60px top 100px, right 100px bottom 60%;
  background-repeat: no-repeat, no-repeat;
}

.bg-dotted-slider {
  display: none;
  background-position: right 150px top 100px, left 150px bottom 100px;
  background-repeat: no-repeat, no-repeat;
  z-index: -1;
  -webkit-animation: none;
  animation: none;
}

.hero-slider {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  -webkit-transition: all linear;
  -o-transition: all linear;
  transition: all linear;
}

.hero-slider:focus {
  outline: none;
}

.hero-midddle-content {
  position: fixed;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  top: 50%;
  z-index: 99;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.stroke-text svg path {
  fill: none;
  stroke: #ffffff;
  stroke-width: 1px;
  stroke-dasharray: 600px;
  stroke-dashoffset: 600px;
  stroke-linecap: round;
  stroke-linejoin: round;
  -webkit-animation: draw-stroke 4s ease-in forwards;
  animation: draw-stroke 4s ease-in forwards;
}

@-webkit-keyframes draw-stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes draw-stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

.hero-midddle-content h1 {
  font-family: "SharpSans-ExtraBold";
  line-height: 90px;
  color: #fff;
  letter-spacing: 2px;
  -webkit-animation: fadeInUp 1.2s linear;
  animation: fadeInUp 1.2s linear;
}

.desktop-hero-slider .slick-slider,
.desktop-hero-slider .slick-slider .slick-list {
  height: 100vh;
}

.hero-slider-items {
  opacity: 0.5;
  height: 100%;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-animation: zoom-in-zoom-out 1s linear;
  animation: zoom-in-zoom-out 1s linear;
}

@-webkit-keyframes zoom-in-zoom-out {
  0% {
    -webkit-transform: scale(1.2, 1.2);
    transform: scale(1.2, 1.2);
  }

  100% {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}

@keyframes zoom-in-zoom-out {
  0% {
    -webkit-transform: scale(1.2, 1.2);
    transform: scale(1.2, 1.2);
  }

  100% {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}

.hero-slider-items::after {
  content: "";
  position: absolute;
  height: 360px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0%, #000000 100%);
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(#000000));
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
}

.hero-slider-items video {
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  top: 0;
  -o-object-fit: cover;
  object-fit: cover;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.hero-slider-bottom {
  position: fixed;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  top: 50%;
  bottom: inherit;
  margin-top: 125px;
  margin-bottom: 0;
  width: 100%;
  text-align: center;
  color: #fff;
  z-index: 99;
}

.hero-slider-bottom p {
  font-size: 18px;
  font-family: "SourceSansPro-Italic";
  color: #fff;
  margin: 0;
  padding: 0;
}

.slider-content {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  color: #fff;
  text-align: center;
  z-index: 10;
}

.slider-content h2 {
  font-family: "SharpSansNo1-Bold";
  font-size: 68px;
  line-height: 92px;
  letter-spacing: 0.015em;
  display: none;
  text-transform: capitalize;
}

.slider-content h4 {
  font-family: "SharpSansNo-Medium";
  font-size: 22px;
  text-transform: capitalize;
  opacity: 0;
}

.desk-side-images {
  position: absolute;
  display: none;
  width: 170px;
  height: 190px;
}

.desk-side-images img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.desk-side-images.left-side {
  left: 11%;
  top: 12%;
}

.desk-side-images.right-side {
  right: 11%;
  bottom: 12%;
}

.desktop-hero-slider .vision-play-btn {
  top: auto;
  bottom: 17%;
  right: 21%;
  transform: translate(-21%, -50%);
  -webkit-transform: translate(-21%, -50%);
  -ms-transform: translate(-21%, -50%);
  display: none;
}

/* slick css */

.slick-arrow {
  display: none !important;
}

.cust-arrows {
  display: none;
  position: absolute;
  top: 50vh;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  /* display: -webkit-box;
  display: -ms-flexbox;
  display: flex; */
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  width: 128px;
  height: 128px;
  background: transparent;
  border: 1px solid #fff;
  border-radius: 50%;
  z-index: 99;
  opacity: 0;
  -webkit-transition: opacity 0.5s;
  -o-transition: opacity 0.5s;
  transition: opacity 0.5s;
}

.cust-arrows span {
  font-size: 14px;
  font-family: "SourceSansPro";
  line-height: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #fff;
}

.cust-arrows.arrow-next {
  right: 8%;
}

.cust-arrows.arrow-prev {
  left: 8%;
}

.cust-arrows:hover {
  border: 1px solid #009dd6;
  -webkit-transform: translate(0, -50%) scale(1.1);
  -ms-transform: translate(0, -50%) scale(1.1);
  transform: translate(0, -50%) scale(1.1);
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.bg-left,
.bg-right {
  display: none;
  position: absolute;
  width: 242px;
  height: 78px;
  z-index: -1;

}

.bg-left img,
.bg-right img {
  opacity: 0.6;
}

.bg-left {
  top: 71vh;
  left: 13%;
}

.bg-right {
  top: 15vh;
  right: 13%;
}

.hero-slider-main-wrapper .slick-dots {
  bottom: 24%;
  z-index: 99;
}

.hero-slider-main-wrapper .slick-dots li,
.custom-dots {
  width: 40px;
  height: 1px;
  cursor: pointer;
}

.custom-dots {
  display: none;
  width: 40px;
  height: 1px;
  background: #fff;
  cursor: pointer;
}

.slick-dots li.slick-active .custom-dots {
  background: #009DD6;
}

/* our vision css section start */
.our-vision-wrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  margin-bottom: 70px;
  margin-top: 70px;
  opacity: 0;
}

.our-vision-video {
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.75;
}

.our-vision-video .vid {
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  height: 100vh;
  top: 0;
  -o-object-fit: cover;
  object-fit: cover;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.our-vision-video .iframe-video,
.our-vision-video .iframe-video .bright-video-wrap,
.our-vision-video .iframe-video .bright-video-wrap .video-js {
  position: absolute;
  width: 100%;
  min-height: 100%;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.over-vision-description {
  position: absolute;
  top: 50%;
  left: 16%;
  -webkit-transform: translate(-16%, -50%);
  -ms-transform: translate(-16%, -50%);
  transform: translate(-16%, -50%);
  z-index: 99;
  color: #fff;
}

.over-vision-description h2 {
  font-family: "SharpSansNo1-Bold";
  font-size: 68px;
  line-height: 92px;
  letter-spacing: 0.015em;
  max-width: 365px;
  margin: 0 0 15px 0;
}

.over-vision-description .description {
  max-width: 520px;
  font-weight: 400;
  font-size: 18px;
  font-family: "SourceSansPro";
  line-height: 30px;
}

/* button play css */
.button-play {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 110px;
  height: 110px;
  border: 1px solid #fff;
  border-radius: 50%;
  cursor: pointer;
}

.button-play::after {
  content: "";
  background-image: url("../../public/assets/images/spinner.svg");
  background-size: contain;
  background-position: center;
  position: absolute;
  top: -10px;
  left: -10px;
  width: 128px;
  height: 128px;
  border-radius: 50%;
  animation: spin 18s linear infinite;
  -webkit-animation: spin 18s linear infinite;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

.vision-play-btn {
  position: absolute;
  top: 50%;
  right: 20%;
  -webkit-transform: translate(-20%, -50%);
  -ms-transform: translate(-20%, -50%);
  transform: translate(-20%, -50%);
  z-index: 99;
  color: #fff;
}

.our-vision-wrapper .bg-dotted {
  background-position: right 60px top 100px, right 100px bottom 15%;
}

.ReactModal__Body--open {
  overflow: hidden;
}

.ReactModal__Overlay {
  background-color: #000 !important;
  z-index: 9999;
}

.ReactModal__Content {
  border: none !important;
  background: #000 !important;
  border-radius: 0 !important;
  padding: 0 !important;
  /* margin: 10px 40px !important; */
  margin: 35px auto !important;
}

.our-vision-video.our-vision-modal {
  width: 71%;
  margin: 0 auto;
  opacity: 1;
}

.video-placeholder {
  font-size: 22px;
  font-family: "SharpSansNo-Medium";
  color: #fff;
  line-height: 21px;
  position: relative;
  z-index: 10;
  padding: 10px 25px;
  text-transform: lowercase;
}

.video-placeholder:first-letter,
.video-placeholder:first-line {
  text-transform: capitalize;
}

.our-vision-video.our-vision-modal .vid {
  height: 100%;
}

.close-modal {
  position: fixed;
  top: 0;
  left: 0;
  padding: 24px;
  min-width: 184px;
  cursor: pointer;
}

.close-modal .back-arrow {
  margin: -4px 8px 1px 0;
}

.close-modal span {
  color: #fff;
  font-size: 18px;
  font-family: "SharpSans-SemiBold";
}

.play-pause {
  background-repeat: no-repeat;
  width: 100px;
  height: 100px;
  position: absolute;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  margin: auto;
  cursor: pointer;
  background-image: url("../../public/assets/images/modal-button-play.png");
  background-size: contain;
  background-position: center;
}

/* job categories section css */
.job-categories-wrapper {
  margin: 100px 0 60px;
}

.job-categories-wrapper .heading {
  color: #fff;
  text-align: center;
  font-family: "SharpSansNo1-Bold";
  letter-spacing: 0.015em;
  margin-bottom: 70px;
}

.job-categories-container {
  max-width: 1160px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  margin: 0 auto;
}

.engg-opptn-content {
  color: #fff;
  font-weight: 400;
  font-size: 18px;
  font-family: "SourceSansPro";
  line-height: 30px;
  margin-bottom: 30px;
}

.job-tiles {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  text-align: left;
  width: 368px;
  min-height: 272px;
  background-color: #0f0f0f;
  color: #fff;
  margin: 8px;
  background-image: url("../../public/assets/images/categories-dots.svg");
  background-position: right top 48px;
  background-repeat: no-repeat;
  cursor: pointer;
  -webkit-transition: 1s ease;
  -o-transition: 1s ease;
  transition: 1s ease;
}

.job-tiles:hover {
  background-color: #009dd6;
  background-image: url("../../public/assets/images/categories-dots.svg");
  background-position: right top 48px;
  background-repeat: no-repeat;
  -webkit-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.content-box {
  padding: 40px 10px 40px 20px;
  max-width: 265px;
  word-break: break-word;
}

.learn-more-box {
  position: relative;
  width: 100%;
  padding: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: 2s ease;
  -o-transition: 2s ease;
  transition: 2s ease;
}

.job-tiles:hover .learn-more-box {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  -webkit-transition: all 2s ease;
  -o-transition: all 2s ease;
  transition: all 2s ease;
}

.learn-text {
  font-size: 18px;
  font-family: "SharpSans-SemiBold";
  line-height: 22px;
  opacity: 0.5;
  -webkit-transition: 2s ease;
  -o-transition: 2s ease;
  transition: 2s ease;
}

.job-tiles:hover .learn-text {
  opacity: 1;
  -webkit-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.learn-more-icon {
  position: absolute;
  left: 88%;
  top: 31%;
  right: 5%;
  bottom: 0;
  opacity: 0;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

.job-tiles:hover .learn-more-icon {
  left: 90%;
  opacity: 1;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

/* job description css */
.job-categ-description {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100vh;
}

.back-wrap {
  display: inline-block;
  position: fixed;
  top: 0;
  left: 0;
  padding: 24px;
  background: #000;
  font-size: 18px;
  font-family: "SharpSans-SemiBold";
  color: #fff;
  min-width: 184px;
  text-align: center;
  z-index: 999;
  cursor: pointer;
}

.back-wrap img {
  margin: -4px 8px 0 0;
}

.job-description-banner {
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.5;
}

.job-categ-description::after {
  content: "";
  position: absolute;
  height: 360px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0%, #000000 100%);
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(#000000));
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
}

.job-categ-content {
  position: absolute;
  top: 50%;
  left: 15%;
  -webkit-transform: translate(-15%, -50%);
  -ms-transform: translate(-15%, -50%);
  transform: translate(-15%, -50%);
  z-index: 99;
}

.text-with-bg {
  width: 870px;
  display: inline-block;
  position: relative;
  margin-left: -20px;
}

.text-with-bg::before {
  position: absolute;
  content: "";
  top: 0;
  left: -29px;
  width: 30px;
  background: #fff;
  height: 100%;
  z-index: -1;
  margin: 0;
  display: none;
}

.job-categ-title {
  position: relative;
  display: inline;
  font-size: 90px;
  line-height: 90px;
  font-family: "SharpSans-ExtraBold";
  color: #2A2A2A;
  background: #fff;
  letter-spacing: 1px;
}

.job-categ-title .cust-heading {
  padding: 0 15px;
  background: #fff;
}

body.job-title-main .job-categ-title .cust-heading {
  padding-top: 0;
}

.job-categ-title .cust-heading {
  padding-top: 15px;
}

.job-categ-title .indent {
  position: relative;
}

.job-description-banner img {
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  height: 100vh;
  top: 0;
  -o-object-fit: cover;
  object-fit: cover;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.job-categ-content .view-open-roles {
  position: relative;
  left: -20px;
  margin: 40px 0 0;
}

.view-open-roles .role-link {
  display: inline-block;
  padding: 20px 32px;
  background: #009DD6;
  color: #fff;
  font-size: 18px;
  font-family: "SharpSans-SemiBold";
  line-height: 22px;
  text-align: center;
}

.view-open-roles .arrow-img {
  padding-left: 12px;
  display: inline-block;
}

.job-categ-middle-content {
  max-width: 1264px;
  width: 100%;
  margin: 207px auto;
}

.job-content-row {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.play-videos-wrapper {
  position: relative;
  max-width: 864px;
  width: 100%;
  margin-right: 40px;
}

.job-content-video {
  opacity: 0.5;
}

.job-categ-middle-content .vision-play-btn {
  left: 50%;
  right: auto;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.full-description {
  max-width: 360px;
  width: 100%;
  color: #fff;
}

.full-description div {
  font-size: 18px;
  font-family: 'SourceSansPro';
  line-height: 30px;
  margin: 20px 0 32px;
}

.full-description .view-open-roles {
  margin: 0;
}


body.show-job-desc .header,
body.show-job-desc .hero-slider-main-wrapper,
body.show-job-desc .our-vision-wrapper,
body.show-job-desc .job-categories-container {
  height: 0;
  min-height: 0;
  margin: 0 !important;
  padding: 0;
  visibility: hidden;
  z-index: -1;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  display: none;
}

body.show-job-desc .arrow {
  display: none;
}

body .arrow.job-cagt-arrow {
  display: none;
}

body.job-title-main .arrow.job-cagt-arrow {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

/* body.show-job-desc .our-vision-wrapper,
body.show-job-desc .job-categories-container {
  display: none;
} */

body.not-found-error .header .menu-nav-links {
  display: none;
}

.page-not-found-wrap {
  min-height: 48vh;
  margin: 150px 50px 100px;
  color: white;
}

.page-not-found-wrap h2 {
  color: white;
}

.department-section {
  min-height: 100vh;
}

.job-page-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  background: #000;
  z-index: 1000;
}

body.category-details header {
  display: none;
}

.television-program {
  padding: 17px 10px;
  border-top: 1px solid #242424;
}

.television-program p {
  font-size: 18px;
  font-family: "SourceSansPro-Italic";
  color: #fff;
  line-height: 30px;
  margin: 0;
  padding: 0;
  text-align: center;
  color: #fff;
}

.television-program p a {
  color: #009DD6;
  text-decoration: underline;
}


/* Footer css start */
.careers-site-footer {
  position: relative;
  padding: 37px 0 44px;
  background: #eeeeee !important;
}

.site-footer-section-1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 1px solid #808080;
  padding-bottom: 10px;
}

.careers-social-links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.social-follow-text {
  font-size: 15px;
  font-family: "SourceSansPro";
  line-height: 18px;
  font-weight: 700;
  color: #2a2a2a;
  margin-bottom: 0;
  margin-right: 5px;
}

.footer-social-icons {
  list-style-type: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0;
  padding: 0;
}

.Instagram,
.Linkedin,
.Facebook,
.Twitter {
  width: 24px;
  height: 24px;
  margin-left: 15px;
}

.Instagram {
  background: url("../../public/assets/images/icon-instagram-square.svg") no-repeat;
  display: block;
}

.Linkedin {
  background: url("../../public/assets/images/icon-linkedin-square.svg") no-repeat;
  display: block;
}

.Facebook {
  background: url("../../public/assets/images/icon-facebook-square.svg") no-repeat;
  display: block;
}

.Twitter {
  background: url("../../public/assets/images/icon-twitter-square.svg") no-repeat;
  display: block;
}

.Help,
.Candidate.Zone {
  width: 24px;
  height: 24px;
  margin-right: 15px;
}

.Help {
  background: url("../../public/assets/images/icon-help-square.svg") no-repeat;
  display: block;
}

.Candidate.Zone {
  background: url("../../public/assets/images/icon-user-square.svg") no-repeat;
  display: block;
}

.footer-social-item {
  display: block;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.other-footer-links ul li {
  padding-left: 43px;
}

.other-footer-links ul li a {
  font-family: "SourceSansPro";
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: #2a2a2a;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.help-icon,
.canditate-icon {
  margin-right: 13px;
}

.site-footer-section-2 {
  padding: 16px 0 40px;
}

.footer-flex-links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.footer-additional-link {
  width: 25%;
}

.footer-additional-link-list {
  height: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.footer-additional-link-list li {
  min-width: 215px;
  width: 215px;
  padding-bottom: 10px;
}

.footer-static-link {
  width: 25%;
}

.footer-nbcu-logo img {
  width: 190px;
}

.footer-additional-link-list .footer-nav-item {
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  font-family: "SourceSansPro";
  color: #2a2a2a;
}

.choice-icon.us {
  background-image: url('../../public/assets/images/your-privacy-choices-icon.svg');
  background-position: left 0px top 4px;
  background-repeat: no-repeat;
  background-size: 30px 14px;
  padding-left: 39px;
}

.menu-static-link li a {
  font-family: "SourceSansPro";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #2a2a2a;
  margin: 0;
}

.footer-additional-link-list #ot-sdk-btn.ot-sdk-show-settings {
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  font-family: "SourceSansPro";
  color: #2a2a2a;
  border: none;
  padding: 0;
}

.footer-additional-link-list #ot-sdk-btn.ot-sdk-show-settings:hover {
  background-color: transparent;
  color: #2a2a2a;
  transform: none;
}

.footer-additional-link-list #ot-sdk-btn.ot-sdk-show-settings.choice-icon.us {
  padding: 0 0 0 39px;
}

.menu-static-link .Accessibility {
  display: inline-block;
  margin-bottom: 7px;
}

.static-links-text a {
  font-family: "SourceSansPro";
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: #2a2a2a;
}

.static-links-text a:first-child::after {
  content: "|";
  padding-left: 4px;
  padding-right: 4px;
}

.footer-logo-btm img {
  width: 250px;
}

.copyright-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.copyright-text {
  font-family: "SourceSansPro";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #2a2a2a;
}


/* Talent community modal css */
.talent-commt-wrapper .link-title {
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  font-family: "SourceSansPro";
  color: #2a2a2a;
  text-transform: capitalize;
  margin-top: 5px;
  display: inline-block;
  cursor: pointer;
}

body.talent-commt-modal .ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.52) !important;
}

/* body.talent-modal-scroll {
  overflow-y: auto;
} */

body.talent-commt-modal .ReactModal__Content.talent-modal {
  position: absolute;
  left: 50%;
  z-index: 2002;
  min-height: 400px;
  max-width: 600px;
  width: 100%;
  top: 10px;
  -webkit-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  border-radius: 3px !important;
  background: #fff !important;
}

body.talent-commt-modal .ReactModal__Content.talent-modal:focus {
  outline: none;
}

.talent-modal-header {
  padding: 8px 10px;
  text-align: center;
  width: 100%;
  background: #eee;
  border: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.talent-modal-header h6 {
  position: relative;
  width: 100%;
  font-weight: 700;
  font-size: 18px;
  font-family: "SourceSansPro";
  line-height: 21px;
  margin: 0;
  color: #000;
  text-align: center;
}

.talent-modal-header .close-talent-modal {
  font-size: 25px;
  font-weight: 700;
  line-height: 13px;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
  font-family: "SourceSansPro-Bold";
}

.talent-modal-body {
  position: relative;
  padding: 35px;
  height: 525px;
  overflow-y: auto;
}

.talent-modal-body h6 {
  text-align: center;
  font-family: "SourceSansPro";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
}

.talent-modal-body .talent-external-links {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.talent-modal-body .region-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.talent-modal-body .region-link-item {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #009DD6;
  margin-top: 10px;
  height: 75px;
  text-align: center;
  padding: 10px 50px;
  width: 270px;
  cursor: pointer;
}

.talent-modal-body .region-link-item .title {
  font-size: 18px;
  font-family: "SourceSansPro";
  font-weight: 500;
  line-height: 24px;
  color: #ffffff;
}

.talent-modal-body .region-link-item .region-more {
  position: absolute;
  top: 50%;
  right: 15px;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.region-iframe-wrapper {
  min-height: 500px;
  height: 500px;
}

.region-iframe-wrapper .iframe-wrapper {
  width: 100%;
  height: 100%;
  border: 0px;
}

/* scroll top top css */
.scroll-top {
  position: absolute;
  bottom: 90px;
  right: 40px;
  z-index: 9999;
  cursor: pointer;
  -webkit-transition: opacity 0.2s ease-out;
  -o-transition: opacity 0.2s ease-out;
  transition: opacity 0.2s ease-out;
}

/* scroll to down css with animation */
.arrow {
  position: fixed;
  bottom: 40px;
  right: -15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 9999;
  /* -webkit-animation: fadeIn 8s ease-in-out;
  animation: fadeIn 8s ease-in-out; */
}

.scroll-down-txt {
  font-family: "SourceSansPro";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 14px;
  color: #fff;
  text-transform: uppercase;
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
  margin: 0 0 50px 0;
  cursor: pointer;
}

.arrow-icons {
  cursor: pointer;
}

.arrow-icons span {
  display: block;
  width: 10px;
  height: 10px;
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  margin: -1px;
  -webkit-animation: animate 1.5s infinite linear;
  animation: animate 1.5s infinite linear;
}

.arrow-icons span:nth-child(2) {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.arrow-icons span:nth-child(3) {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

@-webkit-keyframes animate {
  0% {
    opacity: 0;
    -webkit-transform: rotate(45deg) translate(-3px, -3px);
    transform: rotate(45deg) translate(-3px, -3px);
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    -webkit-transform: rotate(45deg) translate(3px, 3px);
    transform: rotate(45deg) translate(3px, 3px);
  }
}

@keyframes animate {
  0% {
    opacity: 0;
    -webkit-transform: rotate(45deg) translate(-3px, -3px);
    transform: rotate(45deg) translate(-3px, -3px);
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    -webkit-transform: rotate(45deg) translate(3px, 3px);
    transform: rotate(45deg) translate(3px, 3px);
  }
}


video::-webkit-media-controls,
video::-moz-media-controls,
video::-o-media-controls,
video::-ms-media-controls {
  display: none !important;
  opacity: 0 !important;
  appearance: none !important;
  -webkit-appearance: none !important;
}

video::-webkit-media-controls-panel {
  display: none !important;
  opacity: 0 !important;
  appearance: none !important;
  -webkit-appearance: none !important;
}

video::-webkit-media-controls-play-button {
  display: none !important;
  opacity: 0 !important;
  appearance: none !important;
  -webkit-appearance: none !important;
}

video::-webkit-media-controls-start-playback-button {
  display: none !important;
  opacity: 0 !important;
  appearance: none !important;
  -webkit-appearance: none !important;
}

/* @media screen and (prefers-reduced-motion: reduce) {
  * {
    -webkit-animation-duration: 1s !important;
    animation-duration: 1s !important;
    -webkit-animation-iteration-count: 1 !important;
    animation-iteration-count: 1 !important;
    -webkit-transition-duration: 1s !important;
    -o-transition-duration: 1s !important;
    transition-duration: 1s !important;
  }
} */


/* work locations section css start */
body.scroll-hide {
  overflow-y: hidden;
}

.work-location-container {
  padding-top: 60px;
}

.work-location-container .work-location-wrapper {
  max-width: 1184px;
  width: 100%;
  margin: 0 auto;
  color: #fff;
}

.work-location-container .heading {
  font-size: 48px;
  font-family: "SharpSansNo1-Bold";
  line-height: 65px;
  letter-spacing: 0.015em;
  color: #fff;
  text-align: center;
}

.location-slider {
  position: relative;
  margin: 40px 0;
}

.work-location-container .location-slide {
  cursor: pointer;
  text-align: center;
}

.work-location-container .location-slide:focus {
  outline: none;
}

.location-slider .slick-slider .slick-slide {
  padding: 0 10px;
}

.work-location-container .location-slide .location-slider-images {
  width: 100%;
  position: relative;
  height: 200px;
}

.work-location-container .location-slide .location-slider-images img {
  max-height: 200px;
  height: auto;
  position: absolute;
  bottom: 25px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.location-slide .location-slider-images .location-hover-image {
  display: none;
}

.location-slide:hover .location-slider-images .location-hover-image,
.location-slide.active .location-slider-images .location-hover-image {
  display: block;
}

.location-slide:hover .location-slider-images .location-default-image,
.location-slide.active .location-slider-images .location-default-image {
  display: none;
}

.location-slide .location-border-default,
.location-slide .location-border-active {
  width: 100%;
  height: 1px;
  background: rgba(255, 255, 255, 0.2);
}

.location-slide .location-border-active {
  display: none;
}

.location-slide .location-title-border {
  width: 40px;
  height: 4px;
  margin: auto;
  background: #009DD6;
  display: none;
}

.location-slide.active .location-border-active {
  display: block;
}

.location-slide.active .location-border-default {
  display: none;
}

.location-slide .location-title {
  font-size: 16px;
  color: #fff;
  line-height: 20px;
  letter-spacing: 0.02em;
  padding: 24px 0 16px;
  font-family: "SharpSans-SemiBold";
}

.location-slider .loc-cust-arrow {
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  height: 24px;
  width: 24px;
  cursor: pointer;
  display: inline-block;
  background-size: 100% 100% !important;
  background-repeat: no-repeat;
}

.location-slider .loc-cust-arrow.loc-prev {
  left: -20px;
  background-image: url("../../public/assets/images/fa-arrow-left.png") !important;
}

.location-slider .loc-cust-arrow.loc-next {
  right: -20px;
  background-image: url("../../public/assets/images/fa-arrow-right.png") !important;
}

.location-bottom-section {
  display: none;
}

.location-bottom-section.active {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.location-work-desc {
  background: #0F0F0F;
  padding: 100px 40px;
}

.location-desc-inner {
  max-width: 1060px;
  width: 100%;
  margin: 0 auto;
}

.location-desc-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  color: #fff;
}

.location-desc-content .content-left {
  width: 36%;
  margin-right: 3%;
}

.location-desc-content .content-left .title {
  font-size: 32px;
  line-height: 40px;
  font-family: "SharpSansNo-Medium";
  letter-spacing: 0.015em;
}

.location-desc-content .view-open-roles .role-link {
  padding: 16px 13.5px;
}

.location-desc-content .content-right {
  width: 60%;
  font-family: "SourceSansPro";
  font-size: 18px;
  line-height: 30px;
}

.location-desc-content .content-right p {
  margin-bottom: 0;
}

.location-desc-content .content-left .view-open-roles {
  position: relative;
  margin-top: 26px;
  z-index: 10;
}

.location-work-banner {
  position: relative;
  width: 100%;
}

.location-work-banner img {
  width: 100%;
  height: auto;
  -o-object-fit: cover;
  object-fit: cover;
}


/* work locations section css end */

/* media query starts from here */
@media screen and (min-width: 1400px) {
  .hero-slider-main-wrapper {
    padding-bottom: 165vh;
  }

  .desk-side-images {
    width: 232px;
    height: 260px;
  }

  .desk-side-images.left-side {
    left: 11%;
    top: 12%;

  }

  .desk-side-images.right-side {
    right: 11%;
    bottom: 12%;
  }

  .job-content-video img {
    width: 864px;
    height: 486px;
    -o-object-fit: cover;
    object-fit: cover;
  }

  .desktop-hero-slider .vision-play-btn {
    bottom: 21%;
    right: 21%;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    margin: 0;
    padding: 40px;
  }

  .desktop-hero-slider .vision-play-btn .button-play::after {
    top: 31px;
    left: 31px;
  }

}

@media screen and (min-width: 1024px) and (max-width: 1200px) {
  .hero-slider-main-wrapper {
    padding-bottom: 155vh !important;
  }

}

@media screen and (max-width: 1280.98px) {
  .ReactModal__Content {
    margin: 20px auto !important;
  }

  .job-content-row {
    padding: 0 20px;
  }

  .job-categ-content {
    top: 55%;
  }

  /* work location component css start */
  .work-location-container .work-location-wrapper {
    width: 95%;
  }
}

/* When the browser is at least 1200px and less */
@media screen and (max-width: 1199.98px) {
  .navbar-logo img {
    width: 250px;
  }

  .slider-content h2 {
    font-size: 74px;
  }

  /* job catergories section css */
  .job-categories-container {
    max-width: 800px;
  }

  /* footer css */
  .careers-site-footer .container-fluid {
    padding: 0 20px;
  }
}

/* When the browser is at least 1024px and less */
@media screen and (max-width: 1024.98px) {
  .menu-link-list li a {
    margin: 0px 15px;
  }

  .hero-slider-main-wrapper .bg-dotted {
    background-position: right 60px top 130px, right 100px bottom 50%;
  }

  .hero-slider-bottom p {
    font-size: 16px;
  }

  .desk-side-images {
    display: none !important;
  }

  .hero-slider-main-wrapper {
    padding-bottom: 170vh;
  }

  .desktop-hero-slider .vision-play-btn {
    top: auto;
    bottom: 17%;
    right: 19%;
    -webkit-transform: translate(-22%, -50%);
    -ms-transform: translate(-22%, -50%);
    transform: translate(-22%, -50%);
    left: auto;
  }

  .cust-arrows.arrow-next {
    right: 12%;
  }

  .cust-arrows.arrow-prev {
    left: 12%;
  }

  /* our vision section css */

  .our-vision-wrapper {
    margin-bottom: 100px;
    margin-top: 0;
    opacity: 1 !important;
  }

  .our-vision-wrapper .bg-dotted {
    display: none;
  }

  .vision-play-btn {
    width: 110px;
    height: 110px;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    right: 50%;
  }

  .over-vision-description {
    position: absolute;
    top: auto;
    bottom: 80px;
    left: 80px;
    -webkit-transform: initial;
    -ms-transform: initial;
    transform: initial;
    z-index: 10;
    color: #fff;
  }

  .over-vision-description .description {
    max-width: 450px;
  }

  /* job catergories section css */
  .job-categories-container {
    max-width: 800px;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .job-categories-wrapper {
    margin: 150px 0 150px;
  }

  .job-categ-content {
    left: 80px;
    -webkit-transform: initial;
    -ms-transform: initial;
    transform: initial;
    bottom: 150px;
    top: auto;
  }

  .view-open-roles .role-link {
    padding: 20px 21px;
  }

  .job-categ-middle-content {
    margin: 190px auto;
    /* padding-top: 190px; */
    max-width: 881px;
  }

  .job-content-row {
    display: block;
  }

  .play-videos-wrapper {
    max-width: 100%;
    margin: 0 auto;
  }

  .full-description {
    max-width: 864px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 40px auto 0;
  }

  .full-description h3,
  .full-description div {
    width: 45%;
    margin: 0;
  }

  .job-categ-middle-content .view-open-roles {
    display: none;
  }

  .work-location-container {
    padding-top: 100px;
  }

  /* footer section css */
  .footer-additional-link-list li {
    min-width: 180px;
  }

  .footer-additional-link {
    width: 25%;
  }


  /* work location css */
  .work-location-container .work-location-wrapper {
    width: 92%;
  }

  .location-slider .loc-cust-arrow.loc-next {
    right: -15px;
  }

  .location-slider .loc-cust-arrow.loc-prev {
    left: -15px;
  }
}

/* When the browser is at least 991px and less */
@media screen and (max-width: 991.98px) {
  .navbar-logo {
    margin-left: 20px;
    margin-top: 12px;
  }

  .navbar-logo img {
    width: 180px;
  }

  .menu-link-list li a {
    font-size: 14px;
    margin: 0 10px;
    letter-spacing: 0;
  }

  .find-job-link {
    font-size: 14px;
    padding: 20px 10px;
    min-width: 140px;
  }

  .over-vision-description {
    bottom: 45px;
    left: 55px;
  }

  .slider-content h2 {
    font-size: 52px;
    line-height: 52px;
  }

  .desktop-hero-slider .vision-play-btn {
    bottom: 23%;
    right: 23%;
  }

  .cust-arrows.arrow-next {
    right: 7%;
  }

  .cust-arrows.arrow-prev {
    left: 7%;
  }

  .job-categ-content {
    left: 65px;
  }

  .text-with-bg {
    width: 730px;
  }

  .job-categ-title {
    font-size: 86px;
  }

  .engg-opptn-content {
    padding: 0 32px;
  }

  /* .job-tiles {
    width: 350px;
  } */

  .footer-additional-link-list {
    height: 275px;
  }

  .footer-additional-link-list li {
    width: 175px;
    min-width: 175px;
  }

  .footer-additional-link {
    width: 25%;
  }

  .footer-static-link {
    width: 25%;
  }

  .menu-static-link {
    padding-right: 20px;
  }

  /* talent community modal css */
  body.talent-commt-modal .ReactModal__Content.talent-modal {
    position: fixed;
    top: 0;
    left: 0px;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
    height: 100%;
    width: 100%;
    border-radius: 0;
    max-width: 100%;
    margin: 0 !important;
    border-radius: 0 !important;
  }

  .talent-modal-body {
    min-height: 400px;
    height: 100vh;
  }

  .region-iframe-wrapper {
    height: 100vh;
  }

  /* work location component css start */
  .work-location-container .work-location-wrapper {
    width: 100%;
  }

  .location-slider .loc-cust-arrow {
    display: none;
  }

  .location-bottom-section.active {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }

  .location-slide.active .location-title-border {
    display: block;
  }

  .location-work-banner img {
    width: 100%;
    height: auto;
  }

  .location-column-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }

  .location-work-desc {
    padding: 40px 32px 90px;
  }

  .location-desc-content {
    display: block;
  }

  .location-desc-content .content-left,
  .location-desc-content .content-right {
    width: 100%;
    margin: 0;
  }

  .location-desc-content .content-right {
    margin-top: 32px;
  }

  .work-location-container .slick-slider .slick-slide .location-slide.active .location-slider-images .location-hover-image,
  .work-location-container .slick-slider .slick-slide.slick-active.slick-current .location-slide .location-slider-images .location-default-image,
  .work-location-container .slick-slider .slick-slide .location-slide.active .location-border-active {
    display: none;
  }

  .work-location-container .slick-slider .slick-slide .location-slide.active .location-slider-images .location-default-image,
  .work-location-container .slick-slider .slick-slide.slick-active.slick-current .location-slide .location-slider-images .location-hover-image,
  .work-location-container .slick-slider .slick-slide.slick-active.slick-current .location-slide .location-border-active {
    display: block;
  }

  .work-location-container .slick-slider .slick-slide.slick-active.slick-current .location-slide .location-title-border {
    display: block;
  }

  .work-location-container .slick-slider .slick-slide .location-slide.active .location-title-border {
    display: none;
  }

}

/* When the browser is at least 767px and less */
@media screen and (max-width: 767.98px) {

  /* header section css */
  header {
    position: inherit;
    width: 100%;
    z-index: 1;
  }

  .navbar-logo {
    position: absolute;
    z-index: 100;
    top: 25px;
    left: 50%;
    margin: 0;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    display: inline-block;
    width: 250px;
  }

  .navbar-logo img {
    width: 250px;
  }

  .nav-find-job {
    position: absolute;
    width: 100%;
    z-index: 10;
    bottom: 0;
  }

  /* mob menu links css start */
  .mob-hamburger-menu {
    position: absolute;
    display: inline-block;
    top: 20px;
    right: 20px;
    width: 32px;
    height: 32px;
    z-index: 1;
  }

  .hamburger-icon {
    display: inline-block;
    width: 32px;
    height: 32px;
    background-position: center center;
    background: url("../../public/assets/images/hamburger-menu.png") no-repeat;
  }

  .mob-menu-close {
    position: absolute;
    display: none;
    top: 20px;
    left: 20px;
    width: 32px;
    height: 32px;
    z-index: 999999;
  }

  .menu-close-icon {
    display: inline-block;
    width: 32px;
    height: 32px;
    background-position: center center;
    background: url("../../public/assets/images/menu-close.png") no-repeat;
  }

  .mob-menu-close.show {
    display: inline-block;
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
  }


  .menu-nav-links {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: #000000;
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
    transition: -webkit-transform .2s ease-in-out;
    -webkit-transition: -webkit-transform .2s ease-in-out;
    -o-transition: transform .2s ease-in-out;
    transition: transform .2s ease-in-out;
    transition: transform .2s ease-in-out, -webkit-transform .2s ease-in-out;
    z-index: 99999;
  }

  .menu-nav-links.active {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
    transition: -webkit-transform .2s ease-in-out;
    -webkit-transition: -webkit-transform .2s ease-in-out;
    -o-transition: transform .2s ease-in-out;
    transition: transform .2s ease-in-out;
    transition: transform .2s ease-in-out, -webkit-transform .2s ease-in-out;
  }

  .menu-link-list {
    display: block;
    margin-top: 140px;
  }

  .menu-link-list li {
    position: relative;
  }

  .menu-link-list li a {
    display: block;
    font-size: 18px;
    line-height: 22px;
    color: #ffffff !important;
    padding: 20px 0;
    margin: 0 32px;
  }

  .mob-find-job-link {
    position: relative;
    display: block;
    font-size: 18px;
    line-height: 22px;
    font-family: "SharpSans-SemiBold";
    padding: 16px;
    background: #009DD6;
  }

  .menu-link-list li a:after,
  .mob-find-job-link a:after {
    content: "";
    position: absolute;
    top: 50%;
    right: 32px;
    width: 16px;
    height: 18px;
    background: url("../../public/assets/images/menu-arrow.png") no-repeat;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .mob-find-job-link {
    margin: 0 16px;
  }

  .mob-find-job-link a:after {
    right: 16px;
  }


  /* mob menu links css end */

  .hero-midddle-content h1 {
    font-size: 45px;
    line-height: 45px;
  }

  .stroke-text svg {
    height: 34px;
    width: auto;
  }

  .header-navbar {
    -webkit-animation: none;
    animation: none;
    background: none;
  }

  .find-job-link {
    font-size: 18px;
    padding: 25px 28px;
    min-width: 100%;
  }

  .hero-slider-main-wrapper {
    padding-bottom: 200vh;
  }

  .hero-slider-main-wrapper .bg-dotted,
  .bg-dotted-slider,
  .bg-left,
  .bg-right {
    display: none !important;
  }

  .hero-slider-items::after {
    display: none;
  }

  .hero-slider-bottom {
    display: none !important;
  }

  .cust-arrows {
    top: 40vh;
    width: 25px;
    height: 25px;
    border: none;
    border-radius: 0;
    font-size: 0;
  }

  .cust-arrows span {
    display: none;
  }

  .cust-arrows.arrow-prev {
    left: 10px;
    background: url("../../public/assets/images/fa-arrow-left.png");
  }

  .cust-arrows.arrow-next {
    right: 10px;
    background: url("../../public/assets/images/fa-arrow-right.png");
  }

  .cust-arrows:hover {
    border: none;
    -webkit-transform: translate(0, -50%) scale(1);
    -ms-transform: translate(0, -50%) scale(1);
    transform: translate(0, -50%) scale(1);
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
    outline: none;
  }

  .slider-content {
    top: 80%;
    width: 100%;
  }

  .slider-content h2 {
    font-size: 42px;
    padding: 5px;
    line-height: 63px;
    letter-spacing: 0.0325em;
  }

  .slider-content h4 {
    font-size: 22px;
    padding: 0 5px;
  }

  .hero-slider-items {
    opacity: 0.5 !important;
  }

  .desktop-hero-slider .vision-play-btn {
    top: 40%;
    bottom: auto;
    right: auto;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    left: 50%;
  }

  .hero-slider-main-wrapper .slick-dots {
    bottom: 5%;
  }

  .our-vision-wrapper {
    min-height: 812px;
    height: 100%;
    margin-bottom: 40px;
    margin-top: 0;
    opacity: 1 !important;
  }

  .vision-play-btn {
    top: 35%;
  }

  .over-vision-description {
    bottom: 0;
    left: 0;
    margin: 20px 32px;
  }

  .over-vision-description h2 {
    font-size: 42px;
    max-width: 220px;
    margin: 0;
    line-height: 63px;
    margin-bottom: 10px;
    letter-spacing: 0.0325em;
  }

  .over-vision-description .description {
    max-width: 100%;
  }

  .close-modal,
  .back-wrap {
    min-width: 120px;
  }

  .ReactModal__Content {
    margin: 25px auto !important;
    overflow: hidden !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .vision-modal-wrapper {
    height: 300px;
    width: 100%;
  }

  .our-vision-video.our-vision-modal {
    width: 100%;
    top: inherit;
    left: inherit;
    bottom: inherit;
    right: inherit;
    height: 300px;
  }

  .job-categories-wrapper {
    margin: 40px 0 40px;
  }

  .job-categories-wrapper .heading {
    font-size: 42px;
    line-height: 63px;
    text-align: left;
    padding: 0 32px;
    margin-bottom: 40px;
    letter-spacing: 0.0325em;
  }

  .job-categories-container {
    max-width: 600px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .job-tiles {
    width: 335px;
    min-height: 200px;
    background-color: #009dd6;
    background-image: url("../../public/assets/images/categories-dots.svg");
  }

  .content-box {
    padding: 36px 20px;
  }

  .learn-more-box {
    padding: 0;
    border-top: none;
  }

  .learn-text {
    display: none;
  }

  .learn-more-icon {
    top: auto;
    left: auto;
    right: 20px;
    bottom: 20px;
    opacity: 1;
  }

  .job-tiles:hover .learn-more-box {
    border-top: none;
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
  }

  .job-tiles:hover .learn-more-icon {
    left: auto;
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
  }

  .text-with-bg {
    width: 350px;
    margin-left: 0;
    padding: 0;
    padding-top: 0;
  }

  .job-categ-content .view-open-roles {
    position: relative;
    left: 0;
    margin: 20px 0 0;
  }

  .job-categ-title {
    font-size: 44px;
    background: none;
    color: #fff;
    line-height: 55px;
    padding-top: 0;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    -ms-box-shadow: none;
    -o-box-shadow: none;
  }

  .job-categ-title .cust-heading {
    padding: 0;
    background: none;
  }

  body.job-title-main .job-categ-title .cust-heading,
  .job-categ-title .cust-heading {
    padding-top: 0;
  }

  .job-categ-title::before,
  .text-with-bg::before {
    display: none;
  }

  .job-categ-title .indent {
    left: 0
  }

  .job-categ-content {
    left: 30px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    bottom: auto;
    top: 50%;
  }

  .full-description {
    display: block;
    margin: 48px auto 0;
  }

  .full-description h3,
  .full-description div {
    width: 100%;
  }

  .full-description h3 {
    margin-bottom: 20px;
  }

  .job-categ-middle-content .vision-play-btn {
    top: 50%;
  }


  /* footer css */
  .careers-site-footer {
    padding: 37px 0 42px;
  }

  .careers-site-footer .container-fluid {
    position: relative;
  }

  .other-footer-links {
    width: 100%;
    position: absolute;
    left: 0px;
    bottom: 0;
    padding: 0 20px;
  }

  .help-icon,
  .canditate-icon {
    margin-right: 10px;
  }

  .other-footer-links ul li {
    padding-right: 43px;
    padding-left: 0;
  }

  .site-footer-section-1 {
    display: block;
    padding-bottom: 20px;
  }

  .careers-social-links {
    display: block;
  }

  .social-follow-text {
    margin: 0 0 20px 0;
  }

  .footer-social-icons .social-icons {
    margin: 0 15px 0 0;
  }

  .footer-flex-links {
    display: block;
    border-bottom: 1px solid #808080;
  }

  .footer-additional-link-list li {
    width: 100%;
    min-width: 100%;
    padding-bottom: 20px;
  }

  .site-footer-section-2 {
    padding: 20px 0;
  }

  .menu-static-link li {
    padding: 0 0 20px 0;
  }

  .footer-nbcu-logo {
    padding: 20px 0;
  }

  .copyright-section {
    display: block;
    padding-bottom: 60px;
  }

  .copyright-text {
    margin-top: 20px;
  }

  .footer-social-icons li:first-child {
    margin-left: 0;
  }

  .footer-additional-link,
  .footer-static-link {
    width: 100%;
  }

  .footer-additional-link-list {
    height: auto;
    display: block;
  }

  .Help,
  .Candidate.Zone {
    margin-right: 13px;
  }

  .menu-static-link .Accessibility {
    margin-bottom: 0;
  }

  /* scroll to down css */
  .arrow {
    bottom: 110px;
    right: -20px;
    -webkit-animation: none;
    animation: none;
  }

  .scroll-down-txt {
    visibility: hidden;
  }

  .scroll-top {
    bottom: 40px;
    right: 20px;
  }

  /* work location css */
  .work-location-container {
    padding-top: 40px;
  }

  .work-location-container .heading {
    padding: 0 32px;
    font-size: 42px;
    line-height: 63px;
    text-align: left;
    letter-spacing: 0.0325em;
  }

  .location-slider {
    margin: 25px 0 32px;
  }

  .location-work-banner img {
    width: 100%;
    height: auto;
  }

  body.not-found-error .mob-hamburger-menu {
    display: none;
  }

  .talent-modal-main {
    margin-top: 100px;
  }
}

@media screen and (min-width: 395px) and (max-width: 767px) {
  .hero-slider-main-wrapper {
    padding-bottom: 190vh !important;
  }
}

@media screen and (min-height: 750px) and (max-height: 900px) {
  .hero-slider-main-wrapper {
    padding-bottom: 174vh !important;
  }
}

@media screen and (max-width: 375.98px) {
  .job-categ-title {
    font-size: 42px;
  }
}

/* When the browser is at least 360px and less */
@media screen and (max-width: 360.98px) {
  .mob-hamburger-menu {
    right: 10px;
  }

  .hero-slider-main-wrapper {
    padding-bottom: 196vh;
  }

  .hero-midddle-content h1 {
    font-size: 40px;
    line-height: 40px;
  }

  .stroke-text svg {
    height: 30px;
    width: auto;
  }

  .vision-play-btn,
  .vision-play-btn .button-play {
    width: 90px;
    height: 90px;
  }

  .button-play::after {
    top: -6px;
    left: -6px;
    width: 100px;
    height: 100px;
  }

  .job-tiles {
    width: 295px;
  }

  .job-categ-title {
    font-size: 34px;
    background: none;
    color: #fff;
    line-height: 40px;
  }
}

@media screen and (max-width: 320.98px) {
  .hero-slider-main-wrapper {
    padding-bottom: 210vh;
  }

  .navbar-logo img {
    width: 230px;
  }
}

@media screen and (min-width:768px) and (max-width:990px) and (orientation:landscape) {}

@media screen and (min-width:320px) and (max-width:990px) and (orientation:landscape) {
  .hero-midddle-content {
    top: 50%;
  }

  .hero-slider-main-wrapper {
    padding-bottom: 310vh !important;
  }

  .slider-content {
    top: 83% !important;
  }

  .hero-slider-main-wrapper .slick-dots {
    bottom: -12% !important;
  }

  .button-play {
    width: 80px;
    height: 80px;
  }

  .hero-slider-main-wrapper .desktop-hero-slider .vision-play-btn {
    bottom: 5%;
    right: 17%;
  }

  .button-play::after {
    top: -6px;
    left: -6px;
    width: 90px;
    height: 90px;
  }

  .bg-dotted-slider,
  .bg-left,
  .bg-right {
    display: none !important;
  }

  .our-vision-wrapper {
    min-height: 812px;
    height: 100%;
  }

  .over-vision-description .description {
    max-width: 300px;
  }
}

/* media query ends here */